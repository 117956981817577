<template>
  <div class="section-manage-station">
    <div class="container">
      <Dropdown
        class="station-selector"
        :value="selectedStation"
        :options="stationList"
        :taggable="false"
        :multiple="false"
        @onChanged="onDropdownSelected"
        track-by="stationName"
        label="displayName"
        placeholder="เลือกสถานี หรือพิมพ์คำค้นหา"
        no-result-text="ไม่พบสถานี"
        dark />
      <SectionRealtimeCCU />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import SectionRealtimeCCU from '@/report/SectionRealtimeCCU.vue';
import Dropdown from '@/components/Dropdown.vue';

export default {
  components: {
    Dropdown,
    SectionRealtimeCCU,
  },
  data() {
    return {
      selectedStation: null,
    };
  },
  computed: {
    ...mapState({
      userCurrentStation: store => store.user.userCurrentStation,
      stationList: store => store.user.stationList,
    }),
    query() {
      return this.$route.query;
    },
    stationName() {
      return _.get(this.query, 'stationName', null);
    },
  },
  created() {
    this.getCurrentStation();
  },
  methods: {
    getCurrentStation() {
      this.$store.commit('resetUserStation');
      let selectedStation;

      if (this.stationName) {
        selectedStation = [_.find(this.stationList, station => station.stationName === this.stationName)];
      } else {
        // Try using ch3Plus as default station, or the first station as a default station.
        selectedStation = [_.find(this.stationList, station => station.stationName === 'ch3Plus')];
        if (!selectedStation) {
          selectedStation = [_.head(this.stationList)];
        }
      }

      this.$store.commit('setUserStation', selectedStation);
      this.selectedStation = selectedStation;
    },
    onDropdownSelected(selectedArray) {
      if (!selectedArray) {
        return;
      }

      const newValue = selectedArray.pop();
      const oldValue = _.head(selectedArray);
      if (newValue.stationName !== oldValue.stationName) {
        this.selectedStation = [newValue];
        this.$store.commit('setUserStation', [newValue]);
        this.$router.push({
          query: {
            ...this.query,
            stationName: newValue.stationName,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables.scss';
@import '~@/assets/scss/themes/components/page.scss';

.section-manage-station {
  background-color: $primary-bg;
  padding: $spacer 0;

  .station-selector {
    width: 300px;
  }
}

@media (max-width: 767.98px) {
  .summary-header-container {
    .station-selector {
      width: 100%;
    }
  }
}
</style>
